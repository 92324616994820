import React, { useState, useEffect , useRef} from 'react';
import { useMediaQuery } from 'react-responsive'
import './App.css';
import first_loading_gif from "./assets/first_loading.gif"
import man_1_for_menu from "./assets/man_1_for_menu.png"
import man_2_for_menu from "./assets/man_2_for_menu.png"
import github_logo from "./assets/github_logo.png"
import linkedin_logo from "./assets/linkedin_logo.png"
import instagram_logo from "./assets/instagram_logo.png"
import colormkdir_logo from "./assets/colormkdir_logo.png"

import search_light from "./assets/art/search_light.jpg"
import music_life from "./assets/art/music_life.png"
import rainy_day from "./assets/art/rainy_day.png"


function App() {
  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' })
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const menu_ref = useRef();
  const oss_ref = useRef();
  const art_ref = useRef();

  const classToggle_left_oss = () => {
    menu_ref.current.classList.remove("slide_right")
    oss_ref.current.classList.remove("slide_right")
    menu_ref.current.classList.add("slide_left")
    oss_ref.current.classList.add("slide_left")
  }

  const classToggle_right_oss = () => {
    menu_ref.current.classList.remove("slide_left")
    oss_ref.current.classList.remove("slide_left")
    menu_ref.current.classList.add("slide_right")
    oss_ref.current.classList.add("slide_right")
  }

  const classToggle_left_art = () => {
    menu_ref.current.classList.remove("slide_right")
    art_ref.current.classList.remove("slide_right")
    menu_ref.current.classList.add("slide_left")
    art_ref.current.classList.add("slide_left")
  }

  const classToggle_right_art = () => {
    menu_ref.current.classList.remove("slide_left")
    art_ref.current.classList.remove("slide_left")
    menu_ref.current.classList.add("slide_right")
    art_ref.current.classList.add("slide_right")
  }

  return (
    <div>

    
    {isDesktop && <div className="container">
      <div className="loading_container">
        <img src={first_loading_gif} loading="lazy" className="first_loading_gif"/>
      </div>
      {loading ? (
        <div>
        </div>
      ) : (
        <div className="main_content">
          <div className="left_black_box">
          </div>
          <div className="right_black_box">
          </div>
          <div className="menu_box">
            <div ref={menu_ref} className="menu_page">
              <img src={man_1_for_menu} className="man_1_for_menu"/>
              <img src={man_2_for_menu} className="man_2_for_menu"/>
              <div className="border_right"></div>
              <h1 className="my_name">Shosei Mimoto</h1>
              <h2 className="social_media">Social media</h2>
              <img src={github_logo} className="github_logo"/>
              <a className="github_my_account" href="https://github.com/mimomimoto" target="_blank">@mimomimoto</a>
              <img src={linkedin_logo} className="linkedin_logo"/>
              <a className="linkedin_my_account" href="https://www.linkedin.com/in/mimomimoto/" target="_blank">@mimomimoto</a>
              <img src={instagram_logo} className="instagram_logo"/>
              <a className="instagram_my_account" href="https://www.instagram.com/mimomimoto_0320" target="_blank">@mimomimoto_0320</a>
              <h2 className="work">Work</h2>
              <button onClick={classToggle_left_oss} className="oss_txt" type="button">OSS</button>
              <button onClick={classToggle_left_art} className="art_txt" type="button">ART</button>
            </div>
            <div ref={oss_ref} className="oss_page">
              <h1 className="oss_main_txt">OSS</h1>
              <button onClick={classToggle_right_oss} className="dli-chevron-round-left" type="button"></button>
              <a className="colormkdir_txt" href="https://github.com/mimomimoto/colormkdir" target="_blank"> colormkdir </a>
              <p className="colormkdir_detail_txt">colormkdir is a CLI for macOS to customize folder color. It can make directory in specified color. Also, it can manage color list by editing json file.</p>
              <img src={colormkdir_logo} className="colormkdir_logo"/>
            </div>

            <div ref={art_ref} className="art_page">
              <h1 className="art_main_txt">ART</h1>
              <button onClick={classToggle_right_art} className="dli-chevron-round-left" type="button"></button>
              <img src={search_light} className="search_light"/>
              <img src={music_life} className="music_life"/>
              <img src={rainy_day} className="rainy_day"/>
            </div>
            
          </div>

        </div>
      )}
      </div>}
      {!isDesktop && <div className="container">
      <div className="loading_container">
        <img src={first_loading_gif} loading="lazy" className="mobile_first_loading_gif"/>
      </div>
      {loading ? (
        <div>
        </div>
      ) : (
        <div className="main_content">
          <div className="mobile_left_black_box">
          </div>
          <div className="mobile_right_black_box">
          </div>
          <div className="mobile_menu_box">
            <div ref={menu_ref} className="menu_page">
              <img src={man_1_for_menu} className="man_1_for_menu"/>
              <img src={man_2_for_menu} className="man_2_for_menu"/>
              <div className="border_right"></div>
              <h1 className="mobile_my_name">Shosei Mimoto</h1>
              <h2 className="mobile_social_media">Social media</h2>
              <img src={github_logo} className="github_logo"/>
              <a className="mobile_github_my_account" href="https://github.com/mimomimoto" target="_blank">@mimomimoto</a>
              <img src={linkedin_logo} className="linkedin_logo"/>
              <a className="mobile_linkedin_my_account" href="https://www.linkedin.com/in/mimomimoto/" target="_blank">@mimomimoto</a>
              <img src={instagram_logo} className="instagram_logo"/>
              <a className="mobile_instagram_my_account" href="https://www.instagram.com/mimomimoto_0320" target="_blank">@mimomimoto_0320</a>
              <h2 className="mobile_work">Work</h2>
              <button onClick={classToggle_left_oss} className="mobile_oss_txt" type="button">OSS</button>
              <button onClick={classToggle_left_art} className="mobile_art_txt" type="button">ART</button>
            </div>
            <div ref={oss_ref} className="oss_page">
              <h1 className="mobile_oss_main_txt">OSS</h1>
              <button onClick={classToggle_right_oss} className="dli-chevron-round-left" type="button"></button>
              <a className="mobile_colormkdir_txt" href="https://github.com/mimomimoto/colormkdir" target="_blank"> colormkdir </a>
              <p className="mobile_colormkdir_detail_txt">colormkdir is a CLI for macOS to customize folder color. It can make directory in specified color. Also, it can manage color list by editing json file.</p>
              <img src={colormkdir_logo} className="colormkdir_logo"/>
            </div>

            <div ref={art_ref} className="art_page">
              <h1 className="mobile_art_main_txt">ART</h1>
              <button onClick={classToggle_right_art} className="dli-chevron-round-left" type="button"></button>
              <img src={search_light} className="search_light"/>
              <img src={music_life} className="music_life"/>
              <img src={rainy_day} className="rainy_day"/>
            </div>
            
          </div>

        </div>
      )}
      </div>
      }
    </div>
  );
}

export default App;
